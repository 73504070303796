window.addEventListener("load", () => {
  const toggle = document.getElementById("translation-toggle");
  if (!toggle) return;

  const block = document.getElementById("translation-block");
  if (toggle.checked) {
    block.classList.remove("hidden");
  } else {
    block.classList.add("hidden");
  }

  toggle.onchange = () => {
    if (toggle.checked) {
      block.classList.remove("hidden");
    } else {
      block.classList.add("hidden");
    }
  };
});
