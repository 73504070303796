import { hptToggleFullscreen } from "./hpt_exam";

const BORDER_COLORS = ["#eb5757", "#eb5757", "#f2994a", "#f2994a", "#40b04e", "#40b04e"];

window.addEventListener("load", () => {
  const replayVideo = document.getElementById("hpt-replay-video");
  if (!replayVideo) {
    return;
  }

  replayVideo.addEventListener("click", () => {
    replayVideo.paused ? replayVideo.play() : replayVideo.pause();
  });
  document.getElementById("hpt-video-fs").addEventListener("click", () => {
    hptToggleFullscreen();
  });

  const slider = document.getElementById("hpt-replay-slider");
  if (replayVideo.duration) {
    setupViews(slider, replayVideo.duration);
  }
  replayVideo.addEventListener("durationchange", (e) => setupViews(slider, e.target.duration));

  let dragging = false;
  const replayData = document.getElementById("hpt-replay-data");
  const description = document.getElementById("hpt-replay-description");
  const score = document.getElementById("hpt-replay-score");
  replayVideo.addEventListener("timeupdate", (e) => {
    const ts = e.target.currentTime;
    if (!dragging) slider.value = ts;

    const trap = findTrap(ts);
    if (!trap) {
      replayData.classList.add("invisible");
      return;
    }

    description.innerHTML = trap.description;
    score.innerHTML = trap.score;
    score.style.borderColor = BORDER_COLORS[trap.score];
    replayData.classList.remove("invisible");
  });

  slider.addEventListener("change", (e) => (replayVideo.currentTime = e.target.value));
  slider.addEventListener("mousedown", () => (dragging = true));
  slider.addEventListener("mouseup", () => (dragging = false));
});

function setupViews(slider, duration) {
  slider.max = duration;

  const flags = document.querySelectorAll(".replay-flag");
  for (let idx = 0; idx < flags.length; idx++) {
    const flag = flags[idx];
    flag.style.left = `${(parseFloat(flag.dataset.ts) / duration) * 100}%`;
    flag.classList.remove("hidden");
  }

  const traps = document.querySelectorAll(".replay-trap");
  for (let idx = 0; idx < traps.length; idx++) {
    const trap = traps[idx];
    trap.style.left = `${(parseFloat(trap.dataset.start) / duration) * 100}%`;
    trap.style.width = `${((trap.dataset.end - trap.dataset.start) / duration) * 100}%`;
    trap.classList.remove("hidden");
  }
}

function findTrap(ts) {
  const traps = document.querySelectorAll(".replay-trap");
  for (let idx = 0; idx < traps.length; idx++) {
    const trap = traps[idx];
    const start = parseFloat(trap.dataset.start);
    const end = parseFloat(trap.dataset.end);
    if (ts >= start - 1 && ts <= end) {
      const step = (end - start) / 5;
      const score = ts < start ? "!" : `${Math.ceil(5 - (ts - start) / step)}`;
      return { description: trap.dataset.description, score };
    }
  }

  return null;
}
