window.addEventListener("load", () => {
  const el = document.getElementById("students_all");
  if (!el) return;

  const updateValue = () => {
    const values = [];
    document.querySelectorAll("input[type='checkbox']").forEach((checkbox) => {
      if (!checkbox.id.startsWith("student") || checkbox.id === "students_all") {
        return;
      }

      if (checkbox.checked) {
        values.push(checkbox.value);
      }
    });

    document.getElementById("student_ids").value = values;
    if (values.length > 0) {
      document.getElementById("bulk-actions").classList.remove("hidden");
    } else {
      document.getElementById("bulk-actions").classList.add("hidden");
    }
  };

  el.addEventListener("change", ({ target }) => {
    document.querySelectorAll("input[type='checkbox']").forEach((checkbox) => {
      if (!checkbox.id.startsWith("student") || checkbox.id === "students_all") {
        return;
      }

      checkbox.checked = target.checked;
    });

    updateValue();
  });

  document.querySelectorAll("input[type='checkbox']").forEach((checkbox) => {
    if (!checkbox.id.startsWith("student") || checkbox.id === "students_all") {
      return;
    }

    checkbox.addEventListener("change", () => {
      el.checked = false;
      updateValue();
    });
  });
});

window.addEventListener("load", () => {
  const el = document.getElementById("filters-toggle");
  if (!el) return;

  el.addEventListener("click", () => {
    document.getElementById("filters").classList.toggle("hidden");
  });
});

window.addEventListener("load", () => {
  const el = document.getElementById("students-tab-bar");
  if (!el) return;

  el.querySelectorAll("button").forEach((button) =>
    button.addEventListener("click", ({ target }) => {
      el.querySelectorAll("button").forEach((other) => {
        other.classList.remove(
          "text-deep-blue-500",
          "border-b-4",
          "border-deep-blue-500",
          "font-semibold"
        );
        other.classList.add("text-gray-500", "border-b-2", "border-gray-400");
        document.getElementById(other.dataset.tab).classList.add("hidden");
      });

      target.classList.remove("text-gray-500", "border-b-2", "border-gray-400");
      target.classList.add(
        "text-deep-blue-500",
        "border-b-4",
        "border-deep-blue-500",
        "font-semibold"
      );
      const targetEl = document.getElementById(target.dataset.tab);
      targetEl.classList.remove("hidden");
    })
  );
});
