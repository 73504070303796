window.addEventListener("load", () => {
  const timer = document.getElementById("timer");
  if (!timer) return;

  let total = parseInt(timer.dataset.total, 10);
  if (total <= 0) {
    timer.innerText = "Time's Up! Test Complete.";
    return;
  }

  const update = () => {
    total--;
    if (total <= 0) {
      setTimeout(() => window.location.reload(false), 2000);
      window.clearInterval(executor);
      timer.innerText = "Time's Up! Test Complete.";
      return;
    }

    timer.innerText = formatDuration(total);
  };

  update();
  const executor = setInterval(update, 1000);
});

function formatDuration(seconds) {
  const hours = parseInt(seconds / (60 * 60));
  seconds = seconds % (60 * 60);

  const minutes = parseInt(seconds / 60);
  seconds = seconds % 60;

  const sign = seconds < 0 ? "-" : "";

  const pad = (number) => (Math.abs(number) < 10 ? `0${number}` : number);
  return hours > 0
    ? `${sign}${hours}:${pad(minutes)}:${pad(seconds)}`
    : `${sign}${minutes}:${pad(seconds)}`;
}
