import { select } from "d3-selection";
import { arc, line, curveCardinal } from "d3-shape";
import { timeDay, timeWeek } from "d3-time";
import { utcParse, timeFormat } from "d3-time-format";
import { rollup, sum, max, mean } from "d3-array";
import { scaleBand, scaleLinear } from "d3-scale";
import { axisBottom, axisLeft } from "d3-axis";

window.addEventListener("load", () => {
  const el = document.getElementById("tab-bar");
  if (!el) return;

  el.querySelectorAll("button").forEach((button) =>
    button.addEventListener("click", ({ target }) => {
      el.querySelectorAll("button").forEach((other) => {
        other.classList.add("text-gray");
        other.querySelector("span").classList.add("invisible");
        document.getElementById(other.dataset.tab).classList.add("hidden");
      });

      target.classList.remove("text-gray");
      target.querySelector("span").classList.remove("invisible");
      const targetEl = document.getElementById(target.dataset.tab);
      targetEl.classList.remove("hidden");

      if (document.getElementById("bar-chart-interval")) {
        addBarChart(
          document.getElementById("bar-chart"),
          parseInt(document.getElementById("bar-chart-interval").value, 10)
        );
      }
      if (document.getElementById("line-graph-interval")) {
        addLineGraph(
          document.getElementById("line-graph"),
          parseInt(document.getElementById("line-graph-interval").value, 10)
        );
      }
    })
  );
});

window.addEventListener("load", () => {
  const el = document.getElementById("activity-load-more");
  if (!el) return;

  let offset = 10;
  el.addEventListener("click", (e) => {
    e.preventDefault();
    el.classList.add("pointer-events-none");
    el.classList.remove("text-blue");
    el.classList.add("text-gray");

    fetch(`${el.dataset.url}.js?offset=${offset}`, {
      headers: {
        "Content-Type": "text/javascript",
        "X-Requested-With": "XMLHttpRequest",
      },
    }).then(async (res) => {
      if (!res.ok) return;

      const items = await res.text();
      if (items.length > 0) {
        document.getElementById("activity-list").insertAdjacentHTML("beforeend", items);
        initCollapse();
        el.classList.remove("pointer-events-none");
        el.classList.add("text-blue");
        el.classList.remove("text-gray");
        offset += 10;
      } else {
        el.remove();
      }
    });
  });
});

function initCollapse() {
  document.querySelectorAll("[data-collapse]").forEach((button) => {
    button.onclick = () => {
      button.querySelector("svg")?.classList?.toggle("rotate-180");
      document.getElementById(button.dataset.collapse).classList.toggle("hidden");
    };
  });
}
window.addEventListener("load", () => {
  initCollapse();
});

window.addEventListener("load", () => {
  const interval = document.getElementById("bar-chart-interval");
  if (!interval) {
    return;
  }

  interval.addEventListener("change", () => {
    addBarChart(document.getElementById("bar-chart"), parseInt(interval.value, 10));
  });
  const el = document.getElementById("bar-chart");
  addBarChart(el, parseInt(interval.value, 10));
});

window.addEventListener("load", () => {
  const interval = document.getElementById("line-graph-interval");
  if (!interval) {
    return;
  }

  interval.addEventListener("change", () => {
    addLineGraph(document.getElementById("line-graph"), parseInt(interval.value, 10));
  });
  const el = document.getElementById("line-graph");
  addLineGraph(el, parseInt(interval.value, 10));
});

window.addEventListener("load", () => {
  document.querySelectorAll("[data-circular-progress]").forEach((el) => {
    const value = el.dataset.circularProgress,
      radius = el.dataset.radius || 24,
      lineWidth = el.dataset.lineWidth || 8,
      forceColor = el.dataset.forceColor;

    let backColor, frontColor;
    if (forceColor) {
      backColor = el.dataset.backColor;
      frontColor = el.dataset.frontColor;
    } else if (value >= 75) {
      backColor = "rgba(64, 176, 78, 0.2)";
      frontColor = "#40b04e";
    } else if (value >= 40) {
      backColor = "rgba(41, 81, 153, 0.4)";
      frontColor = "#295199";
    } else {
      backColor = "rgba(225, 54, 61, 0.4)";
      frontColor = "#e1363d";
    }

    const svg = select(el)
      .append("svg")
      .attr("viewBox", [0, 0, 2 * radius, 2 * radius])
      .append("g");
    svg.attr("transform", `translate(${radius},${radius})`);

    const backArc = arc()
      .innerRadius(radius - 0.5 * lineWidth)
      .outerRadius(radius - 0.5 * lineWidth)
      .startAngle(0)
      .endAngle(2 * Math.PI);
    svg
      .append("path")
      .attr("d", backArc())
      .attr("fill", "none")
      .attr("stroke", backColor)
      .attr("stroke-width", lineWidth)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round");

    const frontArc = arc()
      .innerRadius(radius - 0.5 * lineWidth)
      .outerRadius(radius - 0.5 * lineWidth)
      .startAngle(0)
      .endAngle(2 * Math.PI * (value / 100));
    svg
      .append("path")
      .attr("d", frontArc())
      .attr("fill", "none")
      .attr("stroke", frontColor)
      .attr("stroke-width", lineWidth)
      .attr("stroke-linejoin", "round")
      .attr("stroke-linecap", "round");
  });
});

function addBarChart(el, interval) {
  const timeFunc = interval === 7 ? timeDay : timeWeek,
    from = timeDay.ceil(timeDay.offset(new Date(), -1 * interval)),
    to = timeFunc.ceil(new Date()),
    dataset = JSON.parse(el.dataset.barChart),
    parseTime = utcParse("%Y-%m-%d"),
    data = Array.from(
      rollup(
        Object.keys(dataset)
          .map((key) => ({ date: timeFunc.round(parseTime(key)), value: dataset[key] }))
          .filter(({ date }) => date >= from && date <= to),
        (v) => sum(v, ({ value }) => value),
        ({ date }) => date
      ),
      ([date, value]) => ({ date, value: value / 3600 })
    ),
    margin = { top: 10, right: 0, bottom: 20, left: 30 },
    width = el.clientWidth,
    height = el.clientHeight;

  select(el).select("svg").remove();
  const svg = select(el)
    .append("svg")
    .attr("class", "text-blue")
    .attr("viewBox", [0, 0, width, height]);

  const domain = timeFunc.range(from, timeFunc.offset(to, 1));
  const x = scaleBand()
    .domain(domain)
    .rangeRound([margin.left, width - margin.right]);

  const xAxis = (g) =>
    g.attr("transform", `translate(0,${height - margin.bottom})`).call(
      axisBottom(x)
        .tickSize(0)
        .tickFormat(timeFormat(interval === 7 ? "%a" : "%d/%m"))
    );
  const xAxisG = svg.append("g");
  xAxisG.call(xAxis);
  xAxisG.select(".domain").remove();
  xAxisG.attr("class", "font-sans font-bold text-xs");

  const yMax = Math.ceil(
    Math.max(
      1,
      max(data, (d) => d.value)
    )
  );
  const y = scaleLinear()
    .domain([0, yMax])
    .nice()
    .rangeRound([height - margin.bottom - 14, margin.top]);

  const yAxis = (g) =>
    g.attr("transform", `translate(${margin.left},0)`).call(
      axisLeft(y)
        .tickSize(0)
        .ticks(yMax, "s")
        .tickFormat((v) => (v === 0 ? v : `${v}h`))
    );
  const yAxisG = svg.append("g");
  yAxisG.call(yAxis);
  yAxisG.select(".domain").remove();
  yAxisG.attr("class", "font-sans font-bold text-xs");

  svg
    .append("g")
    .attr("transform", "translate(20,0)")
    .selectAll("line")
    .data(y.ticks(yMax))
    .enter()
    .append("line")
    .attr("x1", () => x(domain[0]))
    .attr("y1", (d) => y(d))
    .attr("x2", () => x(domain[domain.length - 1]))
    .attr("y2", (d) => y(d))
    .attr("stroke", "#c5c9cf")
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "6,6");

  const barsG = svg.append("g");
  barsG
    .selectAll("rect")
    .data(data)
    .enter()
    .append("rect")
    .attr("x", (d) => x(d.date) + 0.5 * x.bandwidth() - 13)
    .attr("y", (d) => y(d.value))
    .attr("width", 26)
    .attr("height", (d) => height - y(d.value) - margin.bottom - 13)
    .attr("rx", 4)
    .attr("fill", "#cfd7e2");
}

function addLineGraph(el, interval) {
  const timeFunc = interval === 7 ? timeDay : timeWeek,
    from = timeDay.ceil(timeDay.offset(new Date(), -1 * interval)),
    to = timeFunc.ceil(new Date()),
    dataset = JSON.parse(el.dataset.lineGraph),
    parseTime = utcParse("%Y-%m-%d"),
    data = Array.from(
      rollup(
        Object.keys(dataset)
          .map((key) => ({ date: timeFunc.round(parseTime(key)), value: dataset[key] }))
          .filter(({ date }) => date >= from && date <= to),
        (v) => mean(v, ({ value }) => value),
        ({ date }) => date
      ),
      ([date, value]) => ({ date, value })
    ),
    margin = { top: 10, right: 0, bottom: 20, left: 35 },
    width = el.clientWidth,
    height = el.clientHeight;

  select(el).select("svg").remove();
  const svg = select(el)
    .append("svg")
    .attr("class", "text-blue")
    .attr("viewBox", [0, 0, width, height]);

  const domain = timeFunc.range(from, timeFunc.offset(to, 1));
  const x = scaleBand()
    .domain(domain)
    .rangeRound([margin.left, width - margin.right]);

  const xAxis = (g) =>
    g.attr("transform", `translate(0,${height - margin.bottom})`).call(
      axisBottom(x)
        .tickSize(0)
        .tickFormat(timeFormat(interval === 7 ? "%a" : "%d/%m"))
    );
  const axisG = svg.append("g");
  axisG.call(xAxis);
  axisG.select(".domain").remove();
  axisG.attr("class", "font-sans font-bold text-xs");

  const yMax = Math.ceil(
    Math.max(
      1,
      max(data, (d) => d.value)
    )
  );
  const y = scaleLinear()
    .domain([0, yMax])
    .nice()
    .range([height - margin.bottom - 14, margin.top]);

  const yAxis = (g) =>
    g.attr("transform", `translate(${margin.left},0)`).call(
      axisLeft(y)
        .tickSize(0)
        .tickFormat((v) => (v === 0 ? v : `${v}%`))
    );
  const yAxisG = svg.append("g");
  yAxisG.call(yAxis);
  yAxisG.select(".domain").remove();
  yAxisG.attr("class", "font-sans font-bold text-xs");

  svg
    .append("g")
    .attr("transform", "translate(20,0)")
    .selectAll("line")
    .data(y.ticks())
    .enter()
    .append("line")
    .attr("x1", () => x(domain[0]))
    .attr("y1", (d) => y(d))
    .attr("x2", () => x(domain[domain.length - 1]))
    .attr("y2", (d) => y(d))
    .attr("stroke", "#c5c9cf")
    .attr("stroke-width", 1)
    .attr("stroke-dasharray", "6,6");

  const graph = line()
    .curve(curveCardinal)
    .x((d) => x(d.date) + 0.5 * x.bandwidth())
    .y((d) => y(d.value));
  svg
    .append("path")
    .datum(data)
    .attr("fill", "none")
    .attr("stroke", "#cfd7e2")
    .attr("stroke-width", 2)
    .attr("stroke-linejoin", "round")
    .attr("stroke-linecap", "round")
    .attr("d", graph);

  svg
    .append("g")
    .selectAll("circle")
    .data(data)
    .enter()
    .append("circle")
    .attr("cx", (d) => x(d.date) + 0.5 * x.bandwidth())
    .attr("cy", (d) => y(d.value))
    .attr("r", 4)
    .attr("fill", "#355698");
}
