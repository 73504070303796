window.addEventListener("load", () => {
  const enabled = document.getElementById("translation_enabled");
  if (!enabled || enabled.value !== "true") return;

  const nodes = {},
    strings = [];
  for (const item of document.querySelectorAll("[data-translate='']")) {
    const node = item.cloneNode(true);
    delete node.dataset.translate;
    node.innerHTML = "&nbsp;";
    item.insertAdjacentElement("afterend", node);

    nodes[item.innerText] = node;
    strings.push(item.innerText);
  }

  if (strings.length === 0) return;

  fetch("/translate.json", {
    method: "POST",
    body: JSON.stringify({ strings }),
    headers: {
      "X-CSRF-Token": document.querySelector("meta[name=csrf-token]")?.content,
      "Content-Type": "application/json",
    },
  })
    .then((res) => {
      if (!res.ok) throw new Error(`HTTP error, status = ${res.status}`);
      return res.json();
    })
    .then((translations) => {
      Object.keys(translations).forEach((item) => {
        const node = nodes[item];
        node.innerText = new DOMParser()
          .parseFromString(translations[item], "text/html")
          .querySelector("html").textContent;
        delete node[item];
      });
    });
});
