window.addEventListener("load", () => {
  document.querySelectorAll("[role='combobox']").forEach((el) => {
    const optionsEl = document.getElementById(`${el.id}-options`);
    const valueEl = document.getElementById(`${el.id}-value`);

    const options = {};
    for (const child of optionsEl.children) {
      options[child.id] = child;
      if (child.dataset.value === valueEl.value) {
        child.classList.add("font-medium");
      }
      child.addEventListener("click", () => {
        document
          .getElementById(`${el.id}-option-${valueEl.value}`)
          ?.classList?.remove("font-medium");
        child.classList.add("font-medium");
        el.value = child.dataset.displayValue;
        optionsEl.classList.add("hidden");

        if (valueEl.value !== child.dataset.value) {
          valueEl.value = child.dataset.value;
          valueEl.form.submit();
        }
      });
    }

    const items = JSON.parse(el.dataset.items);
    const filterItems = () => {
      optionsEl.classList.remove("hidden");

      const val = el.value.toLowerCase();
      items.forEach(([[name], id]) => {
        const option = options[`${el.id}-option-${id}`];
        if (name.toLowerCase().includes(val)) {
          option.classList.remove("hidden");
        } else {
          option.classList.add("hidden");
        }
      });
    };
    el.addEventListener("input", () => filterItems());
    document
      .getElementById(`${el.id}-button`)
      .addEventListener("click", () => optionsEl.classList.toggle("hidden"));
  });
});
