window.toggleDailySlots = (dayIdx) => {
  const offset = 15 * dayIdx;
  let els = [];
  for (let idx = 0; idx < 15; idx++) {
    const el = document.getElementById(`slots_${offset + idx}`);
    if (el) els.push(el);
  }

  const newState = els.every((el) => !el.checked);
  els.forEach((el) => (el.checked = newState));
};

window.toggleHourlySlots = (hourIdx) => {
  let els = [];
  for (let idx = 0; idx < 7; idx++) {
    const el = document.getElementById(`slots_${15 * idx + hourIdx}`);
    if (el) els.push(el);
  }

  const newState = els.every((el) => !el.checked);
  els.forEach((el) => (el.checked = newState));
};
