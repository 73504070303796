window.addEventListener("load", () => {
  const filterInput = document.getElementById("library-filter");
  if (!filterInput) return;

  filterInput.addEventListener("keyup", function () {
    const filter = this.value;

    for (const item of document.querySelectorAll("#libraries-list a")) {
      if (caseNonsensitiveIncludes(item.textContent, filter)) {
        item.classList.remove("hidden");
      } else {
        item.classList.add("hidden");
      }
    }
  });
});

function caseNonsensitiveIncludes(string1, string2) {
  return string1.toUpperCase().includes(string2.toUpperCase());
}
