import "./hpt_exam";
import "./hpt_replay";
import "./libraries";
import "./quiz";
import "./student";
import "./students_list";
import "./translations";
import "./timer";
import "./combobox";
import "./availability_picker";

window.addEventListener("load", () => {
  const form = document.getElementById("quiz-form");
  if (!form) return;

  const container = document.getElementById("quiz-footer");
  form.addEventListener("submit", () => {
    container.querySelectorAll("button").forEach((btn) => {
      btn.disabled = true;
    });
  });
});

window.addEventListener("load", () => {
  document.querySelectorAll("[data-disable-with]").forEach((btn) => {
    btn.form?.addEventListener("submit", () => (btn.disabled = true));
  });
});
