function hptPlayerClick(videoEl, formEl) {
  if (window.submitted) return;

  const taps = [];
  const tapEls = formEl.querySelectorAll("input[name='taps[]']");
  for (let idx = 0; idx < tapEls.length; idx++) {
    taps.push(parseFloat(tapEls[idx].value));
  }

  const ts = videoEl.currentTime;
  const tap = document.createElement("input");
  tap.type = "hidden";
  tap.name = "taps[]";
  tap.value = ts;
  formEl.appendChild(tap);
  if (isValidAttempt([...taps, ts])) {
    addFlag();
  } else {
    formEl.submit();
    window.submitted = true;
  }
}

function addFlag() {
  const flagsEl = document.getElementById("hpt-flags-container");
  const flag = document.createElement("img");
  flag.src = flagsEl.dataset.flag;
  flag.classList.add("mr-1");
  flag.classList.add("select-none");
  flagsEl.appendChild(flag);
}

function isValidAttempt(taps, window = 7, maxTaps = 7) {
  if (taps.length < maxTaps) {
    return true;
  }

  for (let idx = 0; idx < taps.length; idx++) {
    const rest = taps.slice(idx);
    if (rest.length < maxTaps) continue;

    const ts = taps[idx];
    if (rest.filter((other) => other <= ts + window).length >= maxTaps) return false;
  }

  return true;
}

export function hptToggleFullscreen() {
  const container = document.getElementById("hpt-container");
  const expand = document.getElementById("hpt-video-expand");
  const compress = document.getElementById("hpt-video-compress");

  if (expand.classList.contains("hidden")) {
    // compress
    expand.classList.remove("hidden");
    compress.classList.add("hidden");
    container.classList.remove("fullscreen-player");
    container.classList.add("relative");
  } else {
    // expand
    expand.classList.add("hidden");
    compress.classList.remove("hidden");
    container.classList.add("fullscreen-player");
    container.classList.remove("relative");
  }
}

function showAnswerArea(e) {
  if (e.target.currentTime > 0) {
    document.getElementById("hpt-answer-area")?.classList?.remove("hidden");
    document.getElementById("hpt-video").removeEventListener("timeupdate", showAnswerArea);
  }
}

window.addEventListener("load", () => {
  const video = document.getElementById("hpt-video");
  if (!video) {
    return;
  }

  video.addEventListener("timeupdate", showAnswerArea);
  video.addEventListener("ended", () => {
    document.getElementById("hpt-attempt-form")?.submit();
    window.submitted = true;
  });

  document.getElementById("hpt-answer-area").addEventListener("click", () => {
    hptPlayerClick(video, document.getElementById("hpt-attempt-form"));
  });

  document.getElementById("hpt-video-fs").addEventListener("click", () => {
    hptToggleFullscreen();
  });
});
